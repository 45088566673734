import {
	ApplicationVerifier,
	onAuthStateChanged,
	signInWithPhoneNumber,
	signOut,
	User,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useFirebaseContext } from '../contexts/firebase';

type AuthUser = {
	userId: string;
};

const useFirebaseAuth = () => {
	const [authUser, setAuthUser] = useState<AuthUser | null>(null);
	const [loading, setLoading] = useState(true);
	const { auth } = useFirebaseContext();
	const authStateChanged = (authState: User | null) => {
		if (!authState) {
			setAuthUser(null);
			setLoading(false);
			return;
		}

		setLoading(true);
		setAuthUser({ userId: authState.uid });
		setLoading(false);
	};

	// listen for Firebase state change
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, authStateChanged);
		return () => unsubscribe();
	}, []);

	return {
		authUser,
		loading,
		signInWithPhoneNumber: (phoneNumber: string, applicationVerifier: ApplicationVerifier) =>
			signInWithPhoneNumber(auth, phoneNumber, applicationVerifier),
		signOut: () => signOut(auth),
	};
};

export default useFirebaseAuth;
