import mixpanel from '../globals/mixpanelConfig';
import {
	Analytics,
	logEvent,
	setUserId,
	setUserProperties,
	setDefaultEventParameters,
} from 'firebase/analytics';
import AnalyticsEvent from '../consts/AnalyticsEvent';
import { useRouter } from 'next/router';
import { useFirebaseContext } from '../contexts/firebase';
import { createContext, FunctionComponent, ReactElement, useContext, useEffect } from 'react';
import { useReferrerContext } from './referrer';

const appName = 'application-app';

const _sendEvent = (googleAnalytics: Analytics, pagePath: string) => (
	eventName: AnalyticsEvent,
	properties?: any
) => {
	mixpanel.track(eventName, { appName, pagePath, ...properties });
	logEvent(googleAnalytics, eventName as string, { appName, pagePath, ...properties });
};

const _createUtmProperties = (utmParameters: {
	utm_source?: string;
	utm_medium?: string;
	utm_campaign?: string;
}) => {
	const { utm_source, utm_medium, utm_campaign } = utmParameters;
	return ['first', 'last'].map((position) => ({
		...(utm_source && { [`utm_source [${position} touch]`]: utm_source }),
		...(utm_medium && { [`utm_medium [${position} touch]`]: utm_medium }),
		...(utm_campaign && { [`utm_campaign [${position} touch]`]: utm_campaign }),
	}));
};

const _setUtmParametersOnAnaylticsProfiles = (
	googleAnalytics: Analytics,
	utmParameters: { utm_source?: string; utm_medium?: string; utm_campaign?: string }
) => {
	const [firstTouchProperties, lastTouchProperties] = _createUtmProperties(utmParameters);
	mixpanel.people.set_once(firstTouchProperties);
	mixpanel.people.set(lastTouchProperties);
	setUserProperties(googleAnalytics, lastTouchProperties);
};

const _setUtmParametersOnEvents = (utmParameters: {
	utm_source?: string;
	utm_medium?: string;
	utm_campaign?: string;
}) => {
	const [, lastTouchProperties] = _createUtmProperties(utmParameters);
	mixpanel.register(lastTouchProperties);
	setDefaultEventParameters(lastTouchProperties);
};

export interface AnalyticsContext {
	setUserIdentity: (id: string) => void;
	sendButtonPressEvent: (buttonName: string) => void;
	sendFormFieldCompletedEvent: (fieldName: string) => void;
	reset: () => void;
}

const AnalyticsContext = createContext<AnalyticsContext | null>(null);

export const AnalyticsProvider: FunctionComponent<{ children: ReactElement }> = ({ children }) => {
	const router = useRouter();
	const { analytics: googleAnalytics } = useFirebaseContext();
	const { utmParameters } = useReferrerContext();
	const sendEvent = _sendEvent(googleAnalytics, router.pathname);

	useEffect(() => {
		const handleRouteChange = (url: string) => {
			_sendEvent(googleAnalytics, url)(AnalyticsEvent.PAGE_VIEW);
		};

		router.events.on('routeChangeComplete', handleRouteChange);

		sendEvent(AnalyticsEvent.PAGE_VIEW); // tracks first page hit
		_setUtmParametersOnEvents(router.query);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, []);

	return (
		<AnalyticsContext.Provider
			value={{
				setUserIdentity: (id: string) => {
					mixpanel.identify(id);
					setUserId(googleAnalytics, id);
					_setUtmParametersOnAnaylticsProfiles(googleAnalytics, utmParameters);
				},
				sendButtonPressEvent: (buttonName: string) => {
					sendEvent(AnalyticsEvent.BUTTON_PRESSED, { buttonName });
				},
				sendFormFieldCompletedEvent: (fieldName: string) => {
					sendEvent(AnalyticsEvent.FORM_FIELD_COMPLETED, { fieldName });
				},
				reset: () => {
					mixpanel.reset();
					setUserId(googleAnalytics, undefined as any);
				},
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

export const useAnalytics = () => useContext(AnalyticsContext)!;
