import { ApplicationVerifier, ConfirmationResult } from 'firebase/auth';
import { createContext, FunctionComponent, ReactElement, useContext } from 'react';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

export type Auth = {
	authUser: { userId: string } | null;
	signInWithPhoneNumber: (
		phoneNumber: string,
		applicationVerifier: ApplicationVerifier
	) => Promise<ConfirmationResult>;
	signOut: () => Promise<void>;
	loading: boolean;
};

const AuthContext = createContext<Auth>({
	authUser: null,
	loading: false,
	signInWithPhoneNumber: async () => Promise.reject('Auth not initialized'),
	signOut: async () => {},
});

export const AuthProvider: FunctionComponent<{ children: ReactElement }> = ({ children }) => {
	const auth = useFirebaseAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
