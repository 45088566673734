import React, { ErrorInfo, PropsWithChildren } from 'react';
import * as Sentry from '@sentry/nextjs';

type ErrorBoundaryParams = PropsWithChildren<{}>;
type ErrorBoundaryState = { hasError: boolean; error?: any };

class ErrorBoundary extends React.Component<ErrorBoundaryParams, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryParams) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		Sentry.captureException(error);
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can also log the error to an error reporting service
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="text-center">
					<h1>Something went wrong.</h1>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
