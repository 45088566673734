import isNil from 'lodash/isNil';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import {
	createContext,
	FunctionComponent,
	ReactElement,
	useContext,
	useEffect,
	useState,
} from 'react';

export type ReferrerData = {
	affiliate?: string;
	shareCode?: string;
	utmParameters: {
		utm_source?: string;
		utm_medium?: string;
		utm_campaign?: string;
	};
};

const ReferrerContext = createContext<ReferrerData>({ utmParameters: {} });
const getFirstQueryParameter = (paramName: string, query: ParsedUrlQuery): string | undefined => {
	const value = query[paramName];
	if (isNil(value)) {
		return;
	}
	if (typeof value === 'string') {
		return value;
	} else {
		return value[0];
	}
};

export const ReferrerProvider: FunctionComponent<{ children: ReactElement }> = ({ children }) => {
	const router = useRouter();
	const [affiliate, setAffiliate] = useState<string>();
	const [shareCode, setShareCode] = useState<string>();
	const [utmParameters, setUtmParameters] = useState<ReferrerData['utmParameters']>({});

	useEffect(() => {
		const [affiliateQueryParam, shareCodeQueryParam, utm_source, utm_medium, utm_campaign] = [
			'affiliate',
			'share_code',
			'utm_source',
			'utm_medium',
			'utm_campaign',
		].map((paramName) => getFirstQueryParameter(paramName, router.query));
		if (affiliateQueryParam) {
			setAffiliate(affiliateQueryParam);
		}
		if (shareCodeQueryParam) {
			setShareCode(shareCodeQueryParam);
		}
		if (utm_campaign || utm_medium || utm_source) {
			setUtmParameters({
				...(utm_source ? { utm_source } : {}),
				...(utm_medium ? { utm_medium } : {}),
				...(utm_campaign ? { utm_campaign } : {}),
			});
		}
	}, [router.query, setAffiliate, setShareCode, setUtmParameters]);

	return (
		<ReferrerContext.Provider value={{ affiliate, shareCode, utmParameters }}>
			{children}
		</ReferrerContext.Provider>
	);
};

export const useReferrerContext = () => useContext(ReferrerContext);
