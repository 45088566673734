import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { Container, Nav, Navbar, NavbarBrand, NavLink } from 'react-bootstrap';
import { useAnalytics } from '../../../contexts/analytics';
import { useAuthContext } from '../../../contexts/auth';
import logo from '../../../public/logo.png';

const Header = () => {
	const auth = useAuthContext();
	const analytics = useAnalytics();

	let AccountButton = () => <></>;
	if (auth.authUser) {
		AccountButton = () => (
			<button
				className="apply-btn"
				onClick={() => {
					analytics.sendButtonPressEvent('logout');
					analytics.reset();
					auth.signOut();
				}}
			>
				Logout
			</button>
		);
	}

	return (
		<header>
			<Head>
				<title>Lox Club</title>
			</Head>
			<Navbar expand={'lg'} variant="dark">
				<Container className="my-3">
					<NavbarBrand className={'abs'}>
						<Link href="https://loxclubapp.com">
							<a>
								<Image src={logo} alt="Lox Club" width={115} height={30} />
							</a>
						</Link>
					</NavbarBrand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto" style={{ fontSize: '16px', fontFamily: 'CircularXX-Bold' }}>
							<Nav.Item>
								<NavLink className="d-inline-block" href={'https://loxclubapp.com/about'}>
									Our Story
								</NavLink>
							</Nav.Item>
							<Nav.Item>
								<NavLink className="d-inline-block" href={'https://loxclubapp.com/events'}>
									Events
								</NavLink>
							</Nav.Item>
							<Nav.Item>
								<NavLink
									className="d-inline-block"
									href={'https://loxclubapp.com/gift-a-membership'}
								>
									Gift A Membership
								</NavLink>
							</Nav.Item>
							<div className="d-lg-none">
								<AccountButton />
							</div>
						</Nav>
					</Navbar.Collapse>
					<Nav className={'ms-auto d-none d-lg-block'}>
						<AccountButton />
					</Nav>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;
