import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuthContext } from '../../contexts/auth';
import usePush from '../../hooks/usePush';

const ProtectedRoute: FunctionComponent<{ children: ReactElement<any, any> }> = ({ children }) => {
	const router = useRouter();
	const routerPush = usePush();
	const { loading: authLoading, authUser } = useAuthContext();
	const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

	useEffect(() => {
		const redirect = !authUser && router.pathname !== '/';
		if (redirect) {
			routerPush('/');
		}
		setShouldRedirect(redirect);
	}, [router.pathname, authUser, routerPush]);

	if (authLoading) {
		return <></>;
	}

	if (shouldRedirect) {
		return <div>Redirecting to sign in...</div>;
	}

	return children;
};

export default ProtectedRoute;
