import { FunctionComponent, ReactElement } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';

const Layout: FunctionComponent<{ children: ReactElement<any, any> }> = ({ children }) => {
	return (
		<div>
			<Header />
			<div className="bg-white">
				<Col className="mainContent">
					<Container>
						<Col
							style={{
								justifyContent: 'space-between',
							}}
						>
							<Row style={{ minHeight: '700px' }}>
								<Col md={9} lg={7} xl={7} className={'mx-auto mb-5 pb-5'}>
									<main>{children}</main>
								</Col>
							</Row>
						</Col>
					</Container>
				</Col>
				<Footer />
			</div>
		</div>
	);
};

export default Layout;
