import { MantineProvider } from '@mantine/core';
import type { AppProps } from 'next/app';
import ErrorBoundary from '../components/ErrorBoundary';
import Layout from '../components/Layout';
import ProtectedRoute from '../components/ProtectedRoute';
import { ReferrerProvider } from '../contexts/referrer';
import { AnalyticsProvider } from '../contexts/analytics';
import { AuthProvider } from '../contexts/auth';
import { FirebaseProvider } from '../contexts/firebase';
import '../styles/custom.scss';

const _loxRequiredStyle = {
	invalid: {
		['::-webkit-input-placeholder']: {
			/* WebKit, Blink, Edge */
			color: '#ced4da',
		},
		[':-moz-placeholder']: {
			/* Mozilla Firefox 4 to 18 */
			color: '#ced4da',
			opacity: 1,
		},
		['::-moz-placeholder']: {
			/* Mozilla Firefox 19+ */
			color: '#ced4da',
			opacity: ' 1',
		},
		[':-ms-input-placeholder']: {
			/* Internet Explorer 10-11 */
			color: '#ced4da',
		},
		['::-ms-input-placeholder']: {
			/* Microsoft Edge */
			color: '#ced4da',
		},
		['::placeholder']: {
			/* Most modern browsers support this now. */
			color: '#ced4da',
		},
	},
};

const LabelStyles = {
	label: {
		color: '#fff',
		fontWeight: 700,
	},
};

const RequiredStyles = { required: { color: '#fff', fontWeight: 700 } };

const InputStyles = {
	input: {
		backgroundColor: 'var(--bs-body-bg)',
		caretColor: '#fff',
		color: '#fff',
	},
};

const CommonMantineStyles = {
	..._loxRequiredStyle,
	...LabelStyles,
	...InputStyles,
	...RequiredStyles,
};

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<FirebaseProvider>
			<ReferrerProvider>
				<AnalyticsProvider>
					<AuthProvider>
						<Layout>
							<ErrorBoundary>
								<ProtectedRoute>
									<MantineProvider
										theme={{
											fontFamily: 'CircularXX-Book',
											headings: { fontFamily: 'CircularXX-Bold' },
										}}
										styles={{
											TextInput: () => CommonMantineStyles,
											DatePicker: () => CommonMantineStyles,
											Autocomplete: () => CommonMantineStyles,
											RadioGroup: () => ({
												...CommonMantineStyles,
												radio: {
													backgroundColor: 'var(--bs-body-bg)',
													'&:checked': {
														backgroundColor: 'var(--bs-body-bg)',
														borderColor: '#fff',
													},
												},
											}),
											NativeSelect: () => CommonMantineStyles,
											Textarea: () => CommonMantineStyles,
											InputWrapper: () => CommonMantineStyles,
											Dropzone: () => ({
												root: {
													backgroundColor: 'rgba(255,255,255,.1)',
													'&:hover': { backgroundColor: 'rgba(255,255,255,.2)' },
												},
											}),
										}}
									>
										<Component {...pageProps} />
									</MantineProvider>
								</ProtectedRoute>
							</ErrorBoundary>
						</Layout>
					</AuthProvider>
				</AnalyticsProvider>
			</ReferrerProvider>
		</FirebaseProvider>
	);
}

export default MyApp;
